@import "minima";

//////////////// GLOBAL ////////////////////////

.wrapper {
  max-width: 100%;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 0;
  padding-left: 0;
}

main {
  font-family: 'Baloo Chettan 2', Arial;
  overflow-x: hidden;
  overflow-y:hidden;
  width: 100%; // test push
}

.page-content {
  padding: 0;
}

a:hover {
  text-decoration: none;
}

/////////////////////////////////////////////////



//////////////// HEADER ////////////////////////

a.nav-link, a.nav-link:hover, a.button_header:hover {
  color: #1B1E23;
}

nav {
  padding: 10px 60px;
}

.logo_nav {
  width: 150px ;
}

#navbarNav {
  justify-content: end;
}

ul.navbar-nav {
  margin-right: 15%;
}

.nav-fixed {
  background-color: #FFFFFF
}

.nav-active {
  background-color: #FFFFFF
}

.containerDivLogoLink {
  display: flex;
}

#bandeauContainer.container-fluid {
  --bs-gutter-x: 2rem;
}

.divLogoLink {
  margin: 15% auto;
}

#priseRendezVousLink {
  margin: auto 5%;
}

#navbarCollapse {
  background: white;
}

#navigation_bar {
  width: 100%;
  background: white;
}

.nav-fixed {
  background-color: #FFFFFF;
}


// Small devices (landscape phones, between 576px and 767px)
@media (min-width: 576px) and (max-width: 767px) {
  ul.navbar-nav {
    margin: 5% auto;
  }
  li.nav-item {
    margin: 2%;
  }
  .divLogoLink {
    margin: 5% auto;
  }
}

// Medium devices (tablets, between 768px and 991px)
@media (min-width: 768px) and (max-width: 991px) {
  #priseRendezVousLink {
    margin: auto 34%;
  }
  ul.navbar-nav {
    margin-right: 13%;
  }
  li.nav-item {
    margin: 0;
  }
  #buttonRendezVous.button_test {
    margin-bottom: 7%;
  }
  #bandeauContainer.container-fluid {
    --bs-gutter-x: 18rem;
  }
}

// Large devices (desktops, between 992px and 1199px)
@media (min-width: 992px) and (max-width: 1199px) {
  .containerDivLogoLink {
    display: none;
  }
  ul.navbar-nav {
    margin-right: 9%;
  }
  #priseRendezVousLink {
    margin: auto 6%;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .containerDivLogoLink {
    display: none;
  }
}

////////////////////////////////////////////////



////////// HOME PAGE WALLPAPER BLOC ////////////

.parent{
  position: relative;
  width: 100vw;
  height: 100vh;
}

.wallpaperHomePage{
  width: 100%;
  height: 100%;
  padding-bottom: 23%;
  background-image: url("images/carousel/wallpaper1920x1080.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.fondDegradeLogo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -70%);
  box-shadow: 0 0 50px 60px white;
  opacity: 1;
  background: white;
  width: 35%;
  display: inline-block;
  padding: 2%;
}

// Extra small devices (portrait phones, between 320px and 575px)
@media (min-width: 320px) and (max-width: 575px) {
  .fondDegradeLogo {
    width: 60%;
  }
}

// Small devices (landscape phones, between 576px and 767px)
@media (min-width: 576px) and (max-width: 767px) {
  .fondDegradeLogo {
    width: 60%;
  }
}

// Medium devices (tablets, between 768px and 991px)
@media (min-width: 768px) and (max-width: 991px) {
  .fondDegradeLogo {
    width: 60%;
  }
}


// CODE DE QUENTIN

//.carousel-inner {
//  z-index: 0;
//  filter: brightness(80%);
//  background: white;
//}
//
//.carousel-item {
//  transition: transform 4s ease-in-out;
//}
//
//#myCarousel {
//  height: 100% !important;
//  width: 100% !important;
//}
//
//.text_bloc_carousel {
//  z-index: 99;
//  padding: 20px;
//}
//
//.carousel_title, .carousel_text {
//  color: #FFFFFF;
//}
//
//.text_carousel {
//  position: relative;
//  top: 300px;
//  width: 100%;
//  bottom: 50px;
//  color: white;
//  z-index: 20;
//}
//
//.carousel_subtitle {
//  font-size: 1.2em;
//}
//
//@media only screen and (max-width: 800px) {
//  .text_carousel {
//    padding: 30px;
//    font-size: 0.6em;
//  }
//  #image_carousel {
//    object-fit: cover !important;
//  }
//}
//
//.button_carousel, .button_carousel:visited {
//  margin-top: 20px;
//  color: #1B1E23;
//}
//
//#image_carousel {
//  height: 100vh;
//}

////////////////////////////////////////////////



////////////// PHOTO TEXT BLOC /////////////////

.titleText {
  background: #f7a708;
  font-size: 20px;
  color: white;
  margin: 0 auto 2% auto;
  padding: 2% 0;
  text-align: center;
}

.photoTitleText {
  font-size: 48px;
  color: #263780;
  text-align: center;
}

#photo_text_section {
  background-color: #FFFFFF;
}

.title_photo_text {
  color: #FFFFFF;
}

.text_photo_text {
  color: #FFFFFF;
  font-size: 1.25rem;
  text-align: justify;
}

.image_photo_text {
  border-radius: 20px;
}

// Extra small devices (portrait phones, between 320px and 575px)
@media (min-width: 320px) and (max-width: 575px) {
  .titleText {
    padding: 5%;
  }
  #photoTextLottie {
    width: 100% !important;
    margin: auto;
  }
  #LottieContainer {
    margin-top: 10%;
    margin-bottom: 10%;
    margin-left: 10%;
  }
}

// Small devices (landscape phones, between 576px and 767px)
@media (min-width: 576px) and (max-width: 767px) {
  .titleText {
    padding: 5%;
  }
  #photoTextLottie {
    width: 100% !important;
    margin: auto;
  }
  #LottieContainer {
    margin-top: 10%;
    margin-bottom: 10%;
    margin-left: 10%;
  }
}

////////////////////////////////////////////////



/////////////// GALLERY BLOC ///////////////////

#gallery {
  background-color: #FFFFFF;
}

#gallery_title {
  color: #1B1E23;
}

.gallery_image_card {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 30vh;
}

////////////////////////////////////////////////



/////////////// MAPS BLOC  /////////////////////

#maps_section {
  background-color: #FFFFFF;
}

#maps_section_title {
  color: #1B1E23;
}

.iframe_google {
  text-align: center;
}

////////////////////////////////////////////////



///////////// CONTACT FORM BLOC ////////////////

#form_section {
  background-color: #FFFFFF;
}

#form_title {
  color: #1B1E23;
}

form {
  width: 70%;
}


// Extra small devices (portrait phones, between 320px and 575px)
@media (min-width: 320px) and (max-width: 575px) {
  form {
    width: 90%;
  }
}

// Small devices (landscape phones, between 576px and 767px)
@media (min-width: 576px) and (max-width: 767px) {
  form {
    width: 90%;
  }
}

////////////////////////////////////////////////



///////// BANDEAU IMAGE EN-TETE PAGE ///////////

.bandeau {
  min-height: 40vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.bandeauTitre {
  margin-top: 5rem !important;
  margin-bottom: 0 !important;
  color: #FFFFFF;
}

////////////////////////////////////////////////



/////////// SERVICES GALLERY BLOC //////////////

#title_bloc_servicesGallery {
  color: #1B1E23;
}

.services_gallery_card {
  padding: 1.5em 1.5em;
  border: 1px solid lightgray;
  top: 0px;
  position: relative;
  background-color: #FFFFFF;
  border-radius: 20px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  min-height: 20vh;

  &:hover {
    transition: all 0.3s ease-out;
    box-shadow: 0px 4px 15px rgba(38, 38, 38, 0.2);
    top: -4px;
    border: 1px solid #cccccc;
    background-color: white;
  }

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: #F7A708;
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(2);
    transform-origin: 50% 50%;
    transition: transform 0.3s ease-out;
  }

  &:hover:before {
    transform: scale(4);
  }
}

.services_icon_card {
  width: 2em;
  height: 2em;
}

#servicesButton {
  width: 100%;
}


// Extra small devices (portrait phones, between 320px and 575px)
@media (min-width: 320px) and (max-width: 575px) {
  .services_gallery_card {
    padding: 3em 3em !important;
  }
  .services_icon_card {
    width: 3em;
    height: 3em;
  }
}

// Small devices (landscape phones, between 576px and 767px)
@media (min-width: 576px) and (max-width: 767px) {
  .services_gallery_card {
    padding: 3em 3em !important;
  }
  .services_icon_card {
    width: 3em;
    height: 3em;
  }
}

// Medium devices (tablets, between 768px and 991px)
@media (min-width: 768px) and (max-width: 991px) {
  .services_gallery_card {
    width: 40%;
  }
}

////////////////////////////////////////////////



/////////////// ACCOMPAGNEMENT /////////////////

.accompagnementMainText {
  font-size: 25px;
}

.gradient-border {
  padding: 30px;
  max-width: 100vw;
  --borderWidth: 5px;
  background: #FFFFFF;
  position: relative;
  border-radius: 50px;
  min-height: 53vh;
}

.gradient-border:after {
  content: '';
  position: absolute;
  top: calc(-1 * var(--borderWidth));
  left: calc(-1 * var(--borderWidth));
  height: calc(100% + var(--borderWidth) * 2);
  width: calc(100% + var(--borderWidth) * 2);
  background: linear-gradient(60deg,  #F7A708, #008E39, #00AFCC, #263780, #E83378);
  border-radius: 50px;
  z-index: -1;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}

// Extra small devices (portrait phones, between 320px and 575px)
@media (min-width: 320px) and (max-width: 575px) {
  .gradient-border {
    min-width: 80vw;
  }
}

// Small devices (landscape phones, between 576px and 767px)
@media (min-width: 576px) and (max-width: 767px) {
  .gradient-border {
    min-height: 30vh;
  }
}

// Medium devices (tablets, between 768px and 991px)
@media (min-width: 768px) and (max-width: 991px) {
  .gradient-border {
    min-height: 26vh;
  }
}

// Large devices (desktops, between 992px and 1199px)
@media (min-width: 992px) and (max-width: 1199px) {
  .gradient-border {
    min-height: 43vh;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .gradient-border {
    min-height: 40vh !important;
  }
}

////////////////////////////////////////////////



////////////// MENTIONS LEGALES ////////////////

.containersMentionLegales {
  margin: 5% auto;
}

////////////////////////////////////////////////



//////////////// CTA BLOC //////////////////////

.cta_banner {
  background-color: #263780;
}

a.cta_button {
  margin-bottom: 50px;
  color: #FFFFFF;
}

#ctaContainerBloc {
  width: 57vw;
  height: 100%;
  padding: 30px;
  margin: 0 auto;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 15px;
}

#ctaTextBloc {
  width: 100%;
  font-size: 18px;
  text-align: justify;
}

#ctaUl {
  list-style: inside;
}

ul#ctaUl li {
  margin: 2px;
}

// Extra small devices (portrait phones, between 320px and 575px)
@media (min-width: 320px) and (max-width: 575px) {
  #ctaContainerBloc {
    width: 100%;
    height: 100%;
  }
  #ctaTextBloc {
    width: 95%;
    margin: 20% auto;
  }
}

// Small devices (landscape phones, between 576px and 767px)
@media (min-width: 576px) and (max-width: 767px) {
  #ctaContainerBloc {
    width: 100%;
    height: 100%;
  }
  #ctaTextBloc {
    width: 95%;
    margin: 20% auto;
  }
  .gradient-border {
    min-width: 80vw;
  }
}

// Medium devices (tablets, between 768px and 991px)
@media (min-width: 768px) and (max-width: 991px) {
  #ctaContainerBloc {
    width: 90%;
    height: 100%;
  }
  #ctaTextBloc {
    margin-top: 6%;
  }
  ul#ctaUl {
    margin-top: 10%;
    text-align: center;
  }
  ul#ctaUl li {
    margin: 2%;
  }
}

////////////////////////////////////////////////



////////////////// EXPERTISE ///////////////////

.img_expertise {
  border-radius: 40px;
  //width: 130%;
}

// Medium devices (tablets, between 768px and 991px)
@media (min-width: 768px) and (max-width: 991px) {
  #expertiseTextBloc {
    order: 2 !important;
  }
}

////////////////////////////////////////////////



////////// BANDEAU CONTACT BLOC ////////////////

.contact_bandeau_section {
  margin-top: 80px;
  margin-bottom: 40px;
}

.contact_title {
  color: #1B1E23;
}

////////////////////////////////////////////////



/////////// MISSIONS TEXT BLOC /////////////////

.missions-title, .missions-list > ul {
  color: #FFFFFF;
}

#missionList {
  margin: 3%;
}


// Medium devices (tablets, between 768px and 991px)
@media (min-width: 768px) and (max-width: 991px) {
  #missionTitleContainer {
    order: 1 !important;
    text-align: center;
  }
  .missions-list {
    order: 2 !important;
  }
}

////////////////////////////////////////////////



////////////////// FOOTER //////////////////////

footer {
  display: block;
  margin-top: 10%;
  padding: 5% 0;
  width: 100%;
  background: #00AFCC;
  color: white;
}

.footerContainer {
  position: relative;
}

.divContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.footerDiv {
  margin: auto 3%;
}

.footerMainLogo { // A modifier pour ajouter un cercle derrière le logo
  width: 100%;
}

#footerDivMainLogo {
  width: 10%;
  margin-left: 2px;
}

#footerDivIcons {
  width: 15%;
  text-align: center;
}

.footerIcon {
  width: 40px;
  margin: auto 3%;
  background: white;
  border-radius: 50%;
}

a.mention_footer_link {
  color: white;
  //color: #F7A708;
}


// Extra small devices (portrait phones, between 320px and 575px)
@media (min-width: 320px) and (max-width: 575px) {
  footer {
    height: 96vh;
  }
  .divContainer {
    display: block;
  }
  .footerDiv{
    font-size: 14px;
    position: relative;
    margin: 15% auto;
    text-align: center;
  }
  .footerMainLogo {
    width: 35% !important;
  }
  #footerDivMainLogo {
    width: 100%;
  }
  #footerDivIcons {
    width: 100%;
    text-align: center;
  }
}

// Small devices (landscape phones, between 576px and 767px)
@media (min-width: 576px) and (max-width: 767px) {
  footer {
    height: 96vh;
  }
  .divContainer {
    display: block;
  }
  .footerDiv{
    font-size: 14px;
    position: relative;
    margin: 15% auto;
    text-align: center;
  }
  .footerMainLogo {
    width: 35% !important;
  }
  #footerDivMainLogo {
    width: 100%;
  }
  #footerDivIcons {
    width: 100%;
    text-align: center;
  }
}

// Medium devices (tablets, between 768px and 991px)
@media (min-width: 768px) and (max-width: 991px) {
  #footerDivMainLogo {
    width: 20%;
  }
}

////////////////////////////////////////////////



//////////////////////////////////////////////////////////
///////////////////// ANIMATIONS /////////////////////////
//////////////////////////////////////////////////////////


////////////// ANIMATIONS JS ///////////////////

.animate {
  -webkit-transform: translateY(80px) rotate(-20deg) translateZ(0);
  transform: translateY(50px) rotate(-20deg) translateZ(0);
  -webkit-transition-delay: .3s;
  -o-transition-delay: .3s;
  transition-delay: .3s;
  -webkit-transition: .4s;
  -o-transition: .4s;
  transition: .4s;
}
.bottom-right.inView {
  opacity: 1;
  -webkit-transform: translateY(0px) rotate(0deg) translateZ(0);
  transform: translateY(0px) rotate(0deg) translateZ(0);
}
.inView {
  opacity: 1;
  -webkit-transform: translateY(0px) rotate(0deg) translateZ(0);
  transform: translateY(0px) rotate(0deg) translateZ(0);
}


/* Keyframes */

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.fade-in { animation: fadeIn 2s; }


@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animated-border-button {
  border: none;
  color: #1B1E23;
  outline: none;
  padding: 12px 4px 10px;
  position: relative;
}

.animated-border-button:before,
.animated-border-button:after {
  border: 0 solid transparent;
  transition: all 0.3s;
  content: '';
  height: 0;
  position: absolute;
  width: 0px;
}

.animated-border-button:after {
  border-bottom: 2px solid #1B1E23;
  bottom: -2px;
  left: 0;
}

.animated-border-button:hover:before,
.animated-border-button:hover:after {
  width: 100%;
}

////////////////////////////////////////////////



////////////// BUTTON EFFECT ///////////////////

.button_test {
  padding: 0.75em 2em;
  color: white;
  width: 15%;
  background-color: #E83378;
  border-radius: 50px;
  cursor: pointer;
  border: none;
  text-align: center;
}

#buttonRendezVous.button_test{
  width: 129%;
  font-size: 16px;
}

.button_test:hover {
  padding: 0.75em 2em;
  color: white;
  width: 15%;
  background-color: #263780;
  border-radius: 50px;
  cursor: pointer;
  overflow: hidden;
  border: none;
}

// Extra small devices (portrait phones, between 320px and 575px)
@media (min-width: 320px) and (max-width: 575px) {
  .button_test{
    width: 60%;
  }
  #buttonRendezVous.button_test{
    width: 48%;
    font-size: 15px;
    margin: 5% 25%;
  }
}

// Small devices (landscape phones, between 576px and 767px)
@media (min-width: 576px) and (max-width: 767px) {
  #buttonRendezVous.button_test{
    width: 50%;
    font-size: 15px;
    margin: 5% 21%;
  }
  .button_test{
    width: 40%;
  }
}

// Medium devices (tablets, between 768px and 991px)
@media (min-width: 768px) and (max-width: 991px) {
  #buttonRendezVous.button_test{
    width: 35%;
  }
  .button_test {
    width: 30%;
  }
}

// Large devices (desktops, between 992px and 1199px)
@media (min-width: 992px) and (max-width: 1199px) {
  #buttonRendezVous.button_test{
    width: 142%;
    font-size: 14px;
    margin: 5% 15%;
  }
  li.nav-item{
    padding: 0 !important;
    margin: 2%;
  }
}

////////////////////////////////////////////////



/////////////// PARALLAX EFFECT ////////////////

.parallax{
  background-image:url("images/pngegg-4.png");
  background-size: 30%;
  background-repeat: no-repeat;
}

////////////////////////////////////////////////



///////////// ANIMATIONS CSS ///////////////////


/* Logo rotation */

// Pour ajouter des objets à l'animation, remplacer animation-delay par : durée de l'animation / nombre d'objets

.logoRotationContainer {
  display: grid;
  justify-content: center;
  width: 310px;
  height: 310px;
  border-radius: 50% ;
  float: left;
  shape-outside: circle();
  margin-right: 10%;
  margin-top: 4%;
}

.mainItemDiv {
  display: grid;
  justify-content: center;
  align-content: center;
}

.mainItemDiv > * {
  grid-column: 1;
  grid-row: 1;
}

.originElement{
  align-self: center;
  justify-self: center;
  width: 150px;
  height: 150px;
}

.item {
  animation: spinAround 9s linear infinite;
}

.item-image {
  animation: spinAroundItself 9s linear infinite;
}

.item-overed{
  animation-play-state: paused;
}


// Déplace l'objet sur le côté du cercle, éffectue une rotation autour du centre
@keyframes spinAround {
  from {
    transform: rotate(0deg) translate(120px) scale(.5);
  }
  to {
    transform: rotate(360deg) translate(120px) scale(.5);
  }
}

// Effectue une rotation à l'objet sur lui même
@keyframes spinAroundItself {
  from {
    transform: rotate(var(--rotate));
  }
  to {
    transform: rotate(calc(-360deg + var(--rotate)));
  }
}


// Extra small devices (portrait phones, between 320px and 575px)
@media (min-width: 320px) and (max-width: 575px) {
  .logoRotationContainer {
    float: none;
    margin: 0 auto;
  }
}

// Small devices (landscape phones, between 576px and 767px)
@media (min-width: 576px) and (max-width: 767px) {
  .logoRotationContainer {
    float: none;
    margin: 0 auto;
  }
}

// Medium devices (tablets, between 768px and 991px)
@media (min-width: 768px) and (max-width: 991px) {

}

// Large devices (desktops, between 992px and 1199px)
@media (min-width: 992px) and (max-width: 1199px) {

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .logoRotationContainer{
    margin-top: 4%;
  }
}

////////////////////////////////////////////////


//////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////


////////// ALL THE SNAPPING STUFF //////////////

.scroll-container {
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
}

section {
  height: 100vh;
  scroll-snap-align: center;
}

////////////////////////////////////////////////


/* TOUTES LES TAILLES RESPONSIVE BOOTSTRAP

// Extra small devices (portrait phones, between 320px and 575px)
@media (min-width: 320px) and (max-width: 575px) {

}

// Small devices (landscape phones, between 576px and 767px)
@media (min-width: 576px) and (max-width: 767px) {

}

// Medium devices (tablets, between 768px and 991px)
@media (min-width: 768px) and (max-width: 991px) {

}

// Large devices (desktops, between 992px and 1199px)
@media (min-width: 992px) and (max-width: 1199px) {

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

}

*/
